import React, { useEffect, useState } from 'react';
import {
    WrapperSaved,
    SavedFactorTitel,
    CardGrid,
    Saved,
    MorningFactor,
    LunchFactor,
    EveningFactor,
    LateFactor,
} from './SavedInsulinFactorStyels';
import {
    Wrapper,
    InsulinFactorTitle,
    EntryForm,
    LabelFa,
    DataInputMorning,
    DataInput,
    DataInputLate,
    Button,
} from './SetInsulinFactorStyles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Popup } from '../GlobalStyle';
import Loading from '../PageLoader/PageLoader';
import { Storage } from '@capacitor/storage';

const InsulinFactor: React.FC = () => {
    const { user } = useAuth0();
    const { t } = useTranslation();
    const [insulinfactors, setInsulinFactors] = useState({
        morningfactor: '',
        lunchfactor: '',
        eveningfactor: '',
        latefactor: '',
    });
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    // Funktion: Lokale Speicherung
    const saveToLocalStorage = async (key: string, value: any) => {
        await Storage.set({
            key,
            value: JSON.stringify(value),
        });
    };

    // Funktion: Lokale Daten abrufen
    const getFromLocalStorage = async (key: string) => {
        const result = await Storage.get({ key });
        return result.value ? JSON.parse(result.value) : null;
    };

    // Daten beim Laden der Komponente abrufen (zuerst lokal, dann API)
    useEffect(() => {
        const fetchData = async () => {
            // 1. Lokale Daten abrufen
            const localData = await getFromLocalStorage('insulinFactors');
            if (localData) {
                setInsulinFactors(localData);
            }

            // 2. Daten von der API abrufen, falls online
            if (navigator.onLine && user) {
                try {
                    const response = await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${user.email}`);
                    if (!response.ok) throw new Error('Fehler beim Abrufen der Daten');
                    const data = await response.json();
                    setInsulinFactors(data);

                    // API-Daten lokal speichern
                    await saveToLocalStorage('insulinFactors', data);
                } catch (error) {
                    console.error('Fehler beim Abrufen der API-Daten:', error);
                }
            }

            setLoading(false);
        };

        fetchData();
    }, [user]);

    // Daten speichern (lokal + API, wenn online)
    const handleSetInsulinFactor = async (event: React.FormEvent) => {
        event.preventDefault();

        // Daten lokal speichern
        await saveToLocalStorage('insulinFactors', insulinfactors);

        // API-Aufruf nur, wenn online
        if (navigator.onLine && user) {
            const userId = user.email;

            try {
                const response = await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(insulinfactors),
                });

                // Falls PUT fehlschlägt, POST verwenden
                if (!response.ok) {
                    const postResponse = await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${userId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(insulinfactors),
                    });

                    if (!postResponse.ok) {
                        throw new Error('Fehler beim Erstellen der Insulinfaktoren');
                    }
                }

                // Erfolgreiches Speichern
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 3000);
            } catch (error) {
                console.error('Fehler beim Speichern der API-Daten:', error);
            }
        } else {
            console.log('Daten wurden offline gespeichert.');
        }
    };

    // Synchronisation, wenn eine Verbindung wiederhergestellt wird
    useEffect(() => {
        const syncOfflineData = async () => {
            const localData = await getFromLocalStorage('insulinFactors');
            if (localData && navigator.onLine && user) {
                try {
                    const userId = user.email;
                    await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${userId}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(localData),
                    });
                    console.log('Offline-Daten erfolgreich synchronisiert.');
                } catch (error) {
                    console.error('Fehler beim Synchronisieren der Offline-Daten:', error);
                }
            }
        };

        // Event-Listener hinzufügen
        window.addEventListener('online', syncOfflineData);

        // Event-Listener entfernen
        return () => {
            window.removeEventListener('online', syncOfflineData);
        };
    }, [user]);

    if (loading) return <Loading />;

    return (
        <>
            <Wrapper>
                <InsulinFactorTitle>{t('createinsulinfactor')}</InsulinFactorTitle>
                <EntryForm
                    onSubmit={handleSetInsulinFactor}
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                >
                    <LabelFa htmlFor="factor" id="insulinfactor">
                        {t('daytimefactor')} <br /> {t('enter')}
                        <DataInputMorning
                            type="decimal"
                            name="setedmorningfactor"
                            placeholder={t('factormorning')}
                            maxLength={3}
                            min={0}
                            inputMode="numeric"
                            required
                            onChange={(e) =>
                                setInsulinFactors({ ...insulinfactors, morningfactor: e.target.value })
                            }
                        />
                        <DataInput
                            type="decimal"
                            name="setedlunchfactor"
                            placeholder={t('factornoon')}
                            maxLength={3}
                            min={0}
                            inputMode="numeric"
                            required
                            onChange={(e) =>
                                setInsulinFactors({ ...insulinfactors, lunchfactor: e.target.value })
                            }
                        />
                        <DataInput
                            type="decimal"
                            name="seteveningfactor"
                            placeholder={t('factorevening')}
                            maxLength={3}
                            min={0}
                            inputMode="numeric"
                            required
                            onChange={(e) =>
                                setInsulinFactors({ ...insulinfactors, eveningfactor: e.target.value })
                            }
                        />
                        <DataInputLate
                            type="decimal"
                            name="latefactor"
                            placeholder={t('factorlate')}
                            maxLength={3}
                            min={0}
                            inputMode="numeric"
                            required
                            onChange={(e) =>
                                setInsulinFactors({ ...insulinfactors, latefactor: e.target.value })
                            }
                        />
                    </LabelFa>
                    <Button type="submit">{t('save')}</Button>
                </EntryForm>
                {showPopup && <Popup>{t('dataSaved')}</Popup>}
            </Wrapper>
            <WrapperSaved>
                <SavedFactorTitel>
                    {t('saved')} <br /> {t('insulinfactor')}
                </SavedFactorTitel>
                <CardGrid>
                    <Saved>
                        <MorningFactor>
                            {t('factormorning')}: <br />
                            {insulinfactors.morningfactor || t('nodata')}
                        </MorningFactor>
                        <LunchFactor>
                            {t('factornoon')}: <br />
                            {insulinfactors.lunchfactor || t('nodata')}
                        </LunchFactor>
                        <EveningFactor>
                            {t('factorevening')}: <br />
                            {insulinfactors.eveningfactor || t('nodata')}
                        </EveningFactor>
                        <LateFactor>
                            {t('factorlate')}: <br />
                            {insulinfactors.latefactor || t('nodata')}
                        </LateFactor>
                    </Saved>
                </CardGrid>
            </WrapperSaved>
        </>
    );
};

export default InsulinFactor;
