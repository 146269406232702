import React, { useState, useEffect, useMemo } from 'react';
import { pdf, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { ExportButton, Popup, PopupWrapper, Styles, CountDisplay, PdfButton, DateTimeStyles } from './UserDataToPdfStyles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { IonToast, IonDatetime, IonPopover, IonIcon, IonContent, IonChip } from '@ionic/react';
import { calendarOutline, documentOutline, closeCircleOutline } from 'ionicons/icons';

export default function UserDataToPdf({ cardData }) {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [isChangingDateRange, setIsChangingDateRange] = useState(false); // Added state

  const userCardData = cardData.filter((data) => data.userMail === user.email);

  const filteredData = useMemo(() => {
    if (!startDate || !endDate) return userCardData;
    return userCardData.filter((item) => {
      const itemDate = parseGermanDate(item.date);
      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });
  }, [userCardData, startDate, endDate]);

  function parseGermanDate(dateString) {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('.');
    const [hour, minute, second] = timePart.split(':');
    return new Date(year, month - 1, day, hour, minute, second);
  }

  function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  function resetDateRange() {
    setStartDate('');
    setEndDate('');
    setIsSelectingEndDate(false);
    setShowDatePicker(true);
  }

  function handleDateChange(selectedDate) {
    if (isChangingDateRange || !startDate) {
      setStartDate(selectedDate);
      setEndDate('');
      setIsSelectingEndDate(true);
      setIsChangingDateRange(false);
    } else if (isSelectingEndDate) {
      if (new Date(selectedDate) >= new Date(startDate)) {
        setEndDate(selectedDate);
        setShowDatePicker(false);
      } else {
        setEndDate(startDate);
        setStartDate(selectedDate);
        setShowDatePicker(false);
      }
    }
  }

  async function exportToPDF() {
    setIsExporting(true);
  
    try {
      if (filteredData.length === 0) {
        setToastMessage(t('noDataInRange'));
        setShowToast(true);
        setIsExporting(false);
        return;
      }

      const itemsPerPage = 8;
      const itemsPerRow = 2;
  
      const docChunks = [];
      for (let i = 0; i < filteredData.length; i += itemsPerPage) {
        const chunk = filteredData.slice(i, i + itemsPerPage);
  
        const chunkRows = [];
        for (let j = 0; j < chunk.length; j += itemsPerRow) {
          chunkRows.push(chunk.slice(j, j + itemsPerRow));
        }
        docChunks.push(chunkRows);
      }
  
      const doc = (
        <Document>
          {docChunks.map((chunkRows, chunkIndex) => (
            <Page key={chunkIndex} size="A4" style={Styles.page}>
              <View style={Styles.header}>
                <Text style={Styles.title}>
                  {t('saveddatafrom')} {user.given_name || user.nickname}
                </Text>
                <Text style={Styles.subtitle}>
                  {t('createdon')}: {new Date().toLocaleString()}
                </Text>
              </View>
              <View style={Styles.cardContainer}>
                {chunkRows.map((row, rowIndex) => 
                  row.map((item, itemIndex) => (
                    <View key={`${rowIndex}-${itemIndex}`} style={Styles.card}>
                      <Text style={{ color: '#008080' }}>
                        {t('date')}: {item.date}
                      </Text>
                      <Text style={{ color: '#c92a2a' }}>
                        {t('bloodsugarlevel')}: {item.bloodsugar} mg/dl
                      </Text>
                      <Text style={{ color: '#e67700' }}>
                        {t('carbohydrates')}: {item.carbohydrates} g
                      </Text>
                      <Text style={{ color: '#5c940d' }}>
                        {t('usedinsulin')}: {item.insulin}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('insulinfactor')}: {item.daytimeFactor}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('correctionfactor')}: {item.correctionFactor}
                      </Text>
                      <Text style={{ color: '#364fc7' }}>
                        {t('injectedinsulin')} {t('quantity')}:{' '}
                        {item.calculateUnit}
                      </Text>
                    </View>
                  ))
                )}
              </View>
              <View style={Styles.footer}>
                <Text>InsulinApp.de</Text>
              </View>
              <Image style={Styles.image} src="/pageIcon.png" />
              <Text
                style={Styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          ))}
        </Document>
      );
  
      const pdfBlob = await pdf(doc).toBlob();
      const pdfArrayBuffer = await pdfBlob.arrayBuffer();
      const base64Data = arrayBufferToBase64(pdfArrayBuffer);
  
      const fileName = `gespeicherte_daten_${user.given_name || user.nickname}_${new Date().toLocaleString()}.pdf`;
  
      if (Capacitor.isNativePlatform()) {
        try {
          await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Documents,
            recursive: true,
          });
          setToastMessage(t('pdfSaved'));
        } catch (error) {
          console.error('Fehler beim Speichern:', error);
          setToastMessage(`Fehler beim Speichern: ${error.message}`);
        }
      } else {
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        setToastMessage('PDF erfolgreich heruntergeladen!');
      }
    } catch (error) {
      console.error('PDF export error:', error);
      setToastMessage(`Fehler beim PDF-Export: ${error.message}`);
    } finally {
      setShowToast(true);
      setIsExporting(false);
      setShowPopover(false);
      setStartDate('');
      setEndDate('');
      setIsSelectingEndDate(false);
    }
  }
  
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  return (
    <PopupWrapper>
      <PdfButton onClick={() => setShowPopover(true)}>
        <IonIcon icon={documentOutline} />
        {t('export')}
      </PdfButton>

      <IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
        <Popup className="ion-padding">
          <ExportButton onClick={() => {
            setShowDatePicker(true);
            setIsSelectingEndDate(false);
            setIsChangingDateRange(true); // Update: Added setIsChangingDateRange
          }}>
            <IonIcon icon={calendarOutline} style={{paddingRight: '6px'}} />
            {!startDate ? t('startDate') : !endDate ? t('endDate') : t('changeDateRange')}
          </ExportButton>
          {startDate && (
            <IonChip style={Styles.dateRange}>
              {formatDate(startDate)} {endDate && `- ${formatDate(endDate)}`}
              <IonIcon icon={closeCircleOutline} onClick={resetDateRange} className="ml-1 cursor-pointer" style={{ color: 'black' }} />
            </IonChip>
          )}
          <ExportButton 
            onClick={exportToPDF} 
            disabled={isExporting || !startDate || !endDate}
          >
            {isExporting 
              ? t('exporting') 
              : startDate && endDate
                ? t('exportFilteredData')
                : t('exportAllData')}
          </ExportButton>
          <CountDisplay>
            {t("selectedCount", )} { filteredData.length } | {t("totalCount", )} { userCardData.length }
          </CountDisplay>
        </Popup>
      </IonPopover>

      <IonPopover isOpen={showDatePicker} onDidDismiss={() => setShowDatePicker(false)}>
        <IonContent className="ion-padding">
          <h2>{isSelectingEndDate ? t('endDate') : t('startDate')}</h2>
          <IonDatetime
            presentation="date"
            onIonChange={(e) => handleDateChange(e.detail.value)}
            value={isSelectingEndDate ? endDate || startDate : startDate}
            min={isSelectingEndDate ? startDate : undefined}
          />
          {/* <style>{DateTimeStyles}</style> */}
        </IonContent>
      </IonPopover>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        position="bottom"
      />
    </PopupWrapper>
  );
}

