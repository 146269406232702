import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  CorrectionFactorTitel,
  EntryForm,
  LabelFa,
  DataInputMorning,
  DataInput,
  DataInputLate,
  Button,
} from './SetCorrectionFactorStyles';
import {
  SavedCorrectionFactorTitel,
  CardGrid,
  Saved,
  MorningCorrectionFactor,
  LunchCorrectionFactor,
  EveningCorrectionFactor,
  LateCorrectionFactor,
  WrapperSaved,
} from '../SavedCorrectionFactor/SavedCorrectionFactorStyles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Popup } from '../GlobalStyle';
import Loading from '../PageLoader/PageLoader';

const CorrectionFactor: React.FC = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [correctionfactors, setCorrectionfactors] = useState({
    morningcorrectionfactor: '',
    lunchcorrectionfactor: '',
    eveningcorrectionfactor: '',
    latecorrectionfactor: '',
  });
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  // Helper zum Speichern in localStorage
  const saveToLocalStorage = (data: typeof correctionfactors) => {
    localStorage.setItem('correctionfactors', JSON.stringify(data));
  };

  // Lokale Daten laden, falls keine Internetverbindung besteht
  const loadFromLocalStorage = () => {
    const localData = localStorage.getItem('correctionfactors');
    return localData ? JSON.parse(localData) : null;
  };

  // API-Daten beim ersten Laden abrufen
  useEffect(() => {
    if (user) {
      fetch(`https://insulinapp-api.vercel.app/api/correctionfactors/${user.email}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Fehler beim Abrufen der Daten');
          }
          return response.json();
        })
        .then((data) => {
          setCorrectionfactors(data);
          saveToLocalStorage(data); // Speichere die Daten auch lokal
          setLoading(false);
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error);
          const localData = loadFromLocalStorage();
          if (localData) {
            setCorrectionfactors(localData); // Fallback auf lokale Daten
          }
          setLoading(false);
        });
    }
  }, [user]);

  // Funktion zum Speichern der Korrekturfaktoren
  const handleSetCorrectionFactor = async (event: React.FormEvent) => {
    event.preventDefault();
    const userId = user?.email;

    // Daten lokal speichern
    saveToLocalStorage(correctionfactors);

    try {
      const response = await fetch(`https://insulinapp-api.vercel.app/api/correctionfactors/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(correctionfactors),
      });

      if (!response.ok) {
        // Falls PUT fehlschlägt, versuche POST
        const postResponse = await fetch(`https://insulinapp-api.vercel.app/api/correctionfactors/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(correctionfactors),
        });

        if (!postResponse.ok) {
          throw new Error('Fehler beim Erstellen der Korrekturfaktoren');
        }
      }

      // Zeige das Popup an
      setShowPopup(true);

      // Verstecke das Popup nach 3 Sekunden
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);

    } catch (error) {
      console.error('Fehler beim Speichern der Korrekturfaktoren:', error);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Wrapper>
        <CorrectionFactorTitel>{t('createcorrectionfactor')}</CorrectionFactorTitel>
        <EntryForm
          onSubmit={handleSetCorrectionFactor}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
        >
          <LabelFa htmlFor="morningcorrectionfactor" id="morningcorrectionfactor">
            {t('correctionfactor')} <br /> {t('enter')}
            <DataInputMorning
              type="decimal"
              name="setcorrectionmorningfactor"
              placeholder={t('correctionfactormorning')}
              maxLength={3}
              min={0}
              inputMode="numeric"
              required
              onChange={(e) =>
                setCorrectionfactors({ ...correctionfactors, morningcorrectionfactor: e.target.value })
              }
              style={{ appearance: 'none', MozAppearance: 'textfield' }}
            />
            <DataInput
              type="decimal"
              name="setcorrectiolunchfactor"
              placeholder={t('correctionfactornoon')}
              maxLength={3}
              min={0}
              inputMode="numeric"
              required
              onChange={(e) =>
                setCorrectionfactors({ ...correctionfactors, lunchcorrectionfactor: e.target.value })
              }
              style={{ appearance: 'none', MozAppearance: 'textfield' }}
            />
            <DataInput
              type="decimal"
              name="setcorrectioeveningfactor"
              placeholder={t('correctionfactorevening')}
              maxLength={3}
              min={0}
              inputMode="numeric"
              required
              onChange={(e) =>
                setCorrectionfactors({ ...correctionfactors, eveningcorrectionfactor: e.target.value })
              }
              style={{ appearance: 'none', MozAppearance: 'textfield' }}
            />
            <DataInputLate
              type="decimal"
              name="setlatecorrectiofactor"
              placeholder={t('correctionfactorlate')}
              maxLength={3}
              min={0}
              inputMode="numeric"
              required
              onChange={(e) =>
                setCorrectionfactors({ ...correctionfactors, latecorrectionfactor: e.target.value })
              }
              style={{ appearance: 'none', MozAppearance: 'textfield' }}
            />
          </LabelFa>
          <Button type="submit">{t('save')}</Button>
        </EntryForm>
        {showPopup && <Popup>{t('dataSaved')}</Popup>}
      </Wrapper>

      <WrapperSaved>
        <SavedCorrectionFactorTitel>
          {t('saved')} <br /> {t('correctionfactor')}
        </SavedCorrectionFactorTitel>
        <CardGrid>
          <Saved>
            <MorningCorrectionFactor>
              {t('correctionfactormorning')}: <br />
              {correctionfactors.morningcorrectionfactor || t('nodata')}
            </MorningCorrectionFactor>
            <LunchCorrectionFactor>
              {t('correctionfactornoon')}: <br />
              {correctionfactors.lunchcorrectionfactor || t('nodata')}
            </LunchCorrectionFactor>
            <EveningCorrectionFactor>
              {t('correctionfactorevening')}: <br />
              {correctionfactors.eveningcorrectionfactor || t('nodata')}
            </EveningCorrectionFactor>
            <LateCorrectionFactor>
              {t('correctionfactorlate')}: <br />
              {correctionfactors.latecorrectionfactor || t('nodata')}
            </LateCorrectionFactor>
          </Saved>
        </CardGrid>
      </WrapperSaved>
    </>
  );
};

export default CorrectionFactor;
