import {Preferences} from '@capacitor/preferences'

const storageKey = 'auth0_credentials';

export const auth0Storage = {
  get: async (key: string) => {
    const { value } = await Preferences.get({ key: `${storageKey}_${key}` });
    return value ? JSON.parse(value) : null;
  },
  set: async (key: string, value: any) => {
    await Preferences.set({ key: `${storageKey}_${key}`, value: JSON.stringify(value) });
  },
  remove: async (key: string) => {
    await Preferences.remove({ key: `${storageKey}_${key}` });
  }
};

