import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { domain as auth0Domain, clientId, callbackUri } from "./auth.config";
import { auth0Storage } from "./auth0Storage";
import { Capacitor } from '@capacitor/core';

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: callbackUri
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation={Capacitor.isNativePlatform() ? "localstorage" : "memory"}
      clientStorage={Capacitor.isNativePlatform() ? auth0Storage : undefined}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
